@font-face {
  font-family: "Gordita-Bold";
  src: url("./fonts/gordita-bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/gordita-bold.woff2") format("woff2"),
    url("./fonts/gordita-bold.woff") format("woff"),
    url("./fonts/gordita-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gordita-Regular";
  src: url("./fonts/gordita-regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/gordita-regular.woff2") format("woff2"),
    url("./fonts/gordita-regular.woff") format("woff"),
    url("./fonts/gordita-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 700;
  src: local("Spoqa Han Sans Bold"),
    url("./fonts/SpoqaHanSansBold.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansBold.woff") format("woff"),
    url("./fonts/SpoqaHanSansBold.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 400;
  src: local("Spoqa Han Sans Regular"),
    url("./fonts/SpoqaHanSansRegular.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansRegular.woff") format("woff"),
    url("./fonts/SpoqaHanSansRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 300;
  src: local("Spoqa Han Sans Light"),
    url("./fonts/SpoqaHanSansLight.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansLight.woff") format("woff"),
    url("./fonts/SpoqaHanSansLight.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 100;
  src: local("Spoqa Han Sans Thin"),
    url("./fonts/SpoqaHanSansThin.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansThin.woff") format("woff"),
    url("./fonts/SpoqaHanSansThin.ttf") format("truetype");
}

body {
  margin: 0px;
  padding: 0px;
  background: #fff;
  color: 000;
  overflow-x: hidden;
  /* height: 200%; */
}

a {
  text-decoration: none;
  /* color: #000; */
  color: inherit;
}

.video-container {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.video-container > video {
  /* width: 100%; */
  /* height: 100%; */
  display: grid;
  align-items: center;
  justify-content: center;
}

a:visited {
  text-decoration: none;
  /* color: #000; */
  color: inherit;
}

a:active {
  text-decoration: none;
  /* color: #000; */
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
  /* color: white; */
}

.App {
  height: 100vh;
}

.content {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.content-wrapper-1 {
  display: grid;
  max-width: 940px;
}

.content-wrapper-2 {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 68px;
  align-items: center;
}

.content-paragraph {
  display: grid;
  grid-gap: 8px;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  /* background-color: rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(4px); */
}

.scdw-logo {
  width: 113px;
  height: 52px;
}


.header-bg {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 92px;
  background: rgba(255, 255, 255, 0.04);
  /* blur */

  backdrop-filter: blur(100px);
}

.container-header {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  display: grid;
  padding: 20px 48px;
  grid-template-columns: max-content auto;
  grid-gap: 48px;
  /* align-items: center; */
  height: 52px;
}

.footer {
  width: 100%;
  background: rgba(0, 0, 0, 0.04);
  /* position: sticky; */
  bottom: 0;
  backdrop-filter: blur(100px);
}

.container-footer {
  margin-left: auto;
  margin-right: auto;
  /* padding: 30px 48px; */
  padding: 20px;
  max-width: 1280px;
  display: grid;
  /* grid-template-columns: max-content auto 40px 40px; */
  grid-template-columns: max-content auto 24px 24px;
  /* grid-gap: 60px; */
  grid-gap: 24px;
  align-items: center;
}

.container-center-page {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  margin-left: 80px;
  margin-right: 80px;
}

.container-full-page {
  position: relative;
  margin-left: 80px;
  margin-right: 80px;
}

.title {
  margin-bottom: 32px;
}

.heading-1 {
  font-family: "Gordita-Bold";
  font-size: 60px;
  line-height: 84px;
  letter-spacing: 0em;
  text-align: left;
  word-break: keep-all;
}

.nav-item {
  font-family: "Gordita-Bold";
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  word-break: keep-all;
}

.heading-2 {
  font-family: "Gordita-Bold", "Spoqa Han Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  word-break: keep-all;
}

.heading-3 {
  font-family: "Gordita-Bold";
  font-size: 32px;
  line-height: 45px;
  letter-spacing: 0em;
  word-break: keep-all;
}

.heading-5 {
  font-family: "Gordita-Bold";
  font-size: 24px;
  line-height: 140%;
  word-break: keep-all;
}

.heading-6 {
  font-family: "Gordita-Bold";
  font-size: 24px;
  line-height: 160%;
  word-break: keep-all;
}

.body-1 {
  font-family: "Spoqa Han Sans";
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0em;
  word-break: keep-all;
}

.btn {
  display: inline-block;
  padding: 16px 20px;
  border: 1.5px solid black;
  font-family: "Spoqa Han Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  word-break: keep-all;
}

.btn:hover {
  color: white;
  background-color: black;
}

.btn-dark {
  padding: 16px 20px;
  background: #000;
  font-family: "Spoqa Han Sans";
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  justify-self: end;
  word-break: keep-all;
}

.btn-dark:hover {
  background-color: #333;
}

.ticket {
  font-family: "Gordita-Bold";
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0em;
}

.container-glitch {
  position: relative;
  height: 100vh;
}

.container-bg-text {
  text-align: center;
  display: grid;
  align-items: center;
  height: 100vh;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.bg-text-test {
  margin: 40px;
  font-size: 6em;
  font-weight: 300;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.canvas {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* overflow: visible; */
}

.spectrum-logo,
.insta-logo,
.fb-logo {
  height: 24px;
}

.sponsor-container {
  /* width: 100%; */
  margin-top: 80px;
  display: grid;
  grid-row-gap: 80px;

  /* grid-auto-flow: column; */
}

.sponsor-tier {
  display: grid;
  width: 100%;
  grid-gap: 40px;
  text-align: left;
}

.sponsor-item-container-1 {
  display: grid;
  grid-template-columns: max-content;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sponsor-item-container-2 {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sponsor-item-container-3 {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.platinum > img {
  height: 80px;
}

.gold > img {
  height: 60px;
}

.silver > img {
  height: 40px;
}

.sponsor-item {
  display: inline-block;
}

.accordion-item {
  padding: 24px 8px;
  border-top: 1px solid #c6ced5;
  overflow: hidden;
  display: grid;
}

.accordion-item:last-child {
  border-bottom: 1px solid #c6ced5;
}

.accordion-title {
  display: grid;
  grid-template-columns: auto 32px;
}

.accordion-content {
  padding-top: 12px;
}

.partner-container {
  display: grid;
  grid-gap: 120px;
  margin-top: 40px;
}

.partner-item {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.partner-item-info {
  display: grid;
  grid-gap: 40px;
}

.partner-item-info-text {
  display: grid;
  grid-gap: 8px;
}

.logo-eo {
  height: 60px;
}

.logo-duotone {
  height: 40px;
}

.margin-1 {
  height: 300px;
}

.bookmark {
  pointer-events: none;
}

.bookmark-container {
  z-index: 100;
  /* height: 60px; */
  display: grid;
  grid-template-columns: 120px max-content;
  grid-gap: 32px;
}

.bookmark-item-container {
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 48px;
  pointer-events: auto;
}

.bookmark-item {
  padding-bottom: 4px;
  cursor: pointer;
}

.bookmark-text {
  font-family: "Gordita-Bold";
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0em;
  word-break: keep-all;
}

@media (max-width: 1160px) {
  body {
    overflow-x: hidden;
  }

  .container-center-page,
  .container-full-page {
    margin-left: 40px;
    margin-right: 40px;
  }

  .container-header {
    padding-left: 40px;
    padding-right: 40px;
  }

  /* .container-footer {
    padding: 30px 48px;
  } */

  .content-wrapper-2 {
    grid-template-columns: 1fr;
    grid-gap: 48px;
  }

  .title {
    margin-bottom: 24px;
  }

  .heading-1 {
    font-size: 54px;
    line-height: 76px;
    letter-spacing: 0em;
    text-align: left;
  }

  .heading-2 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0em;
  }

  .nav-item {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0em;
  }

  .body-1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
  }


  .bg-text-test {
    margin: 20px;
    font-size: 4em;
  }

  .partner-item {
    grid-gap: 40px;
    grid-template-columns: 1fr;
  }

  .coupon-btn {
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .coupon-btn > svg {
    width: 400px;
  }

  .sponsor-item-container-3 {
    grid-gap: 32px;
  }

  .platinum > img {
    height: 60px;
  }

  .gold > img {
    height: 50px;
  }

  .silver > img {
    height: 32px;
  }

  .bookmark-item-container {
    align-items: center;
    justify-content: center;
    display: grid;
    grid-gap: 24px;
  }
}

@media (max-width: 600px) {
  .header-bg {
    height: 89px;
  }
  .video-container > video {
    /* width: 200%; */
    /* height: 100vh; */
    /* height: 100%; */
    display: grid;
    align-items: center;
    justify-content: center;
  }

  .container-center-page,
  .container-full-page {
    margin-left: 15px;
    margin-right: 15px;
  }

  .container-header {
    padding: 12px 15px;
    grid-gap: 12px;
    height: 34px;
  }

  /* .container-footer {
    padding: 20px;
    grid-gap: 24px;
    grid-template-columns: max-content auto 24px 24px;
  } */

  .margin-1 {
    height: 240px;
  }

  .content-wrapper-2 {
    grid-template-columns: 1fr;
    grid-gap: 48px;
  }

  .btn {
    font-size: 16px;
    line-height: 17px;
  }

  .btn-dark {
    padding: 10px 12px;
    font-size: 14px;
    line-height: 14px;
    z-index: 200;
  }

  .title {
    margin-bottom: 24px;
  }

  .heading-1 {
    font-size: 32px;
    line-height: 45px;
    letter-spacing: 0em;
  }

  .heading-2 {
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0em;
  }

  .heading-6 {
    font-family: "Gordita-Bold";
    font-size: 18px;
    line-height: 160%;
  }

  .nav-item {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0em;
  }

  .body-1 {
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0em;
  }

  .scdw-logo {
    width: 60px;
    height: 27.58px;
  }

  .bg-text-test {
    margin: 20px;
    font-size: 2.4em;
  }

  .coupon-btn {
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .coupon-btn > svg {
    width: 276px;
    height: 100px;
  }

  .partner-item {
    grid-gap: 40px;
    grid-template-columns: 1fr;
  }

  .logo-eo {
    height: 50px;
  }

  .logo-duotone {
    height: 30px;
  }

  .content-wrapper-2 {
    grid-gap: 40px;
  }

  .sponsor-tier {
    grid-gap: 24px;
  }

  .sponsor-item-container-2 {
    grid-template-columns: max-content;
    grid-gap: 32px;
  }

  .sponsor-item-container-3 {
    grid-template-columns: max-content;
    grid-gap: 32px;
  }

  .platinum > img {
    height: 60px;
  }

  .gold > img {
    height: 50px;
  }

  .silver > img {
    height: 30px;
  }

  .bookmark {
    grid-template-columns: 1fr;
  }

  .bookmark-container {
    z-index: 100;
    height: 60px;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }

  .bookmark-item-container {
    align-items: center;
    justify-content: center;
    display: grid;
    margin-top: 50px;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 48px; */
  }

  .bookmark-item {
    text-align: center;
    padding-bottom: 0px;
    cursor: pointer;
  }

  .dummy-logo {
    display: none;
  }

  .bookmark-text {
    font-size: 14px;
    line-height: 15px;
  }

  .btn {
    padding: 16px;
  }
}

@media (max-width: 320px) {
  .btn {
    padding: 12px;
  }
}
