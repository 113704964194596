.speaker-page {
  margin-top: 216px;
  margin-bottom: 120px;
}

.speaker-card-small-text-1 {
  font-family: "Spoqa Han Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  word-break: keep-all;
}

.speaker-card-small-text-2 {
  font-family: "Spoqa Han Sans";
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  word-break: keep-all;
}

.speaker-card-small-text-3 {
  font-family: "Gordita-Regular";
  font-size: 20px;
  line-height: 160%;
  word-break: keep-all;
}

.speaker-card-small-text-4 {
  font-family: "Gordita-Regular";
  font-size: 18px;
  line-height: 29px;
  word-break: keep-all;
}

.speaker-card-large-text-1 {
  font-family: "Gordita-Bold";
  font-size: 36px;
  line-height: 160%;
  letter-spacing: 0em;
  word-break: keep-all;
}
.speaker-card-large-text-2 {
  font-family: "Gordita-Bold";
  font-size: 24px;
  line-height: 160%;
  letter-spacing: 0em;
  word-break: keep-all;
}
.speaker-card-large-text-3 {
  font-family: "Gordita-Regular";
  font-size: 24px;
  line-height: 160%;
  letter-spacing: 0em;
  word-break: keep-all;
}
.speaker-card-large-text-4 {
  font-family: "Gordita-Regular";
  font-size: 28px;
  line-height: 45px;
  letter-spacing: 0em;
  word-break: keep-all;
}

.speaker-card-tag-text {
  font-family: "Gordita-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  word-break: keep-all;
}

.speaker-page-tag-text {
  font-family: "Gordita-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  word-break: keep-all;
}

.speaker-card-bg-container {
  position: absolute;
  display: grid;
  grid-template-columns: auto 16px;
  grid-template-rows: 16px auto;
}

.speaker-card-bg {
  background-color: rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(10px);
}

.speaker-card-container {
  position: relative;
  height: max-content;
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: auto 16px;
  grid-template-rows: 16px auto;
  /* overflow: hidden */
}

.speaker-card-wrapper {
  display: grid;
  grid-template-columns: auto 180px;
  width: 480px;
  grid-gap: 16px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.03);
  /* backdrop-filter: blur(10px); */
}

.speaker-card-content {
  margin-top: 24px;
  margin-left: 24px;
  display: grid;
  grid-gap: 36px;
  margin-bottom: 24px;
  position: relative;
}

.speaker-card-info {
  display: grid;
  grid-gap: 4px;
}

.speaker-card-tags-container {
  height: 32px;
  position: relative;
}

.speaker-card-tags-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;
  position: absolute;
  left: 0;
}

.speaker-page-tags-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;
  /* position: absolute; */
  left: 0;
}

.speaker-card-tags {
  padding: 4px 8px;
  border: 1px solid black;
  border-radius: 100px;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
}

.symbol {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100px;
}

.opening {
  background-color: #22bffc;
}

.talks {
  background-color: #90f78a;
}

.keynote {
  background-color: #388df7;
}

.speaker-card-img {
  position: absolute;
  right: -16px;
  top: -16px;
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
  background: #e5e5e5;
}

.speaker-card-img > img {
  width: 180px;

  object-fit: cover;
}

.speaker-card-arrow {
  position: absolute;
  width: 60px;
  height: 60px;
  right: -16px;
  top: 147px;
}

.speaker-card-arrow > svg {
  width: 60px;
  height: 60px;
}

.speaker-list-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.speaker-list-nav {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 80px;
  margin-bottom: 40px;
}

.speaker-list-nav-list-selected {
  font-family: "Gordita-Bold";
  font-size: 32px;
  line-height: 51px;
  letter-spacing: 0em;
  border-bottom: 2px solid #141415;
  cursor: pointer;
}

.speaker-list-nav-list-unselected {
  font-family: "Gordita-Regular";
  font-size: 32px;
  line-height: 51px;
  letter-spacing: 0em;
  opacity: 0.4;
  cursor: pointer;
}

.speaker-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* align-content: space-around; */
  /* grid-template-columns: max-content max-content;
  grid-column-gap: 120px;
  grid-template-rows: repeat(8, 28px 72px auto 72px); */
}

.speaker-page-wrapper {
  margin-top: 80px;
  margin-bottom: 120px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto 280px;
}

.speaker-page-info-container {
  display: grid;
  grid-gap: 40px;
}

.speaker-page-info {
  display: grid;
  grid-gap: 8px;
  grid-template-rows: max-content max-content;
}

.speaker-page-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 280px;
  height: 280px;
  background: #d7d7d7;
  overflow: hidden;
}

.speaker-page-img > img {
  width: 280px;
  object-fit: cover;
}

.speaker-page-content-container {
  margin-bottom: 120px;
}

.speaker-page-content-title {
  margin-bottom: 16px;
}

.speaker-page-content-wrapper {
  display: grid;
  grid-gap: 40px;
  max-width: 940px;
}

.speaker-page-nav-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 120px;
}

.speaker-bg-img {
  position: absolute;
  pointer-events: none;
  z-index: -10;
}

.left-item-margin {
  height: 80px;
  width: 496px;
  margin-right: 120px;
}

.right-item-margin {
  height: 80px;
}

@media (max-width: 1280px) {
  .left-item-margin,
  .right-item-margin {
    height: 0px;
    margin-right: 0px;
    width: 0px;
  }

  .speaker-list-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 48px;
    grid-template-rows: auto;
  }

  .first,
  .third,
  .fifth,
  .seventh,
  .nineth,
  .eleventh,
  .thirteenth,
  .fifteenth {
    grid-column: 1;
    grid-row: auto;
    justify-self: left;
  }

  .second,
  .fourth,
  .sixth,
  .eighth,
  .tenth,
  .twelveth,
  .fourteenth,
  .sixteenth {
    grid-column: 1;
    grid-row: auto;
    justify-self: right;
  }
}

.react-add-to-calendar {
  display: flex;
  flex-direction: horizontal;
  cursor: pointer;
}

.react-add-to-calendar__dropdown > ul {
  margin: 0;
  padding-left: 24px;
  list-style: none;
  display: grid;
  grid-template-columns: max-content max-content;
  /* grid-gap: 12px; */
}

.react-add-to-calendar__dropdown > ul > li {
  display: inline-block;
  border-left: 1px solid black;
  padding: 0 20px;
  text-align: center;
}

.react-add-to-calendar__dropdown > ul > li:last-child {
  display: inline-block;
  border-left: 1px solid black;
  padding: 0 0 0 20px;
  text-align: center;
}

@media (max-width: 1160px) {
  .speaker-page-nav-wrapper {
    grid-template-columns: max-content;
    grid-gap: 40px;
    justify-content: center;
  }

  .speaker-page-img {
    height: 240px;
    width: 240px;
  }

  .speaker-page-img > img {
    width: 240px;
  }

  .speaker-page-wrapper {
    margin-top: 80px;
    margin-bottom: 120px;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto 240px;
  }

  .speaker-list-nav-list-selected {
    font-size: 24px;
    line-height: 38.4px;
  }

  .speaker-list-nav-list-unselected {
    font-size: 24px;
    line-height: 38.4px;
  }
}

@media (max-width: 600px) {
  .speaker-list-wrapper {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .speaker-page {
    margin-top: 102px;
  }

  .speaker-page-nav-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    justify-content: center;
  }
  .speaker-page-wrapper {
    margin-top: 0;
    grid-template-columns: auto;
  }

  .speaker-page-info-container {
    display: grid;
    grid-gap: 32px;
  }

  .speaker-page-img {
    grid-row-start: 1;
    position: relative;
    width: calc(100vw - 30px);
    height: calc(100vw - 30px);
  }

  .speaker-page-img > img {
    width: calc(100vw - 30px);
    height: calc(100vw - 30px);
    object-fit: cover;
  }

  .speaker-card-small-text-1 {
    font-size: 16px;
  }

  .speaker-card-small-text-2 {
    font-size: 12px;
  }
  .speaker-card-small-text-3 {
    font-size: 16px;
  }

  .speaker-card-large-text-1 {
    font-size: 20px;
  }

  .speaker-card-large-text-2 {
    font-size: 15px;
  }

  .speaker-card-large-text-3 {
    font-size: 20px;
  }

  .first,
  .third,
  .fifth,
  .seventh,
  .nineth,
  .eleventh,
  .thirteenth,
  .fifteenth,
  .second,
  .fourth,
  .sixth,
  .eighth,
  .tenth,
  .twelveth,
  .fourteenth,
  .sixteenth {
    grid-column: 1;
    grid-row: auto;
    justify-self: stretch;
  }
  .speaker-card-img {
    right: -10px;
    top: -10px;
    width: 120px;
    height: 120px;
  }

  .speaker-card-arrow {
    right: -10px;
    top: 101px;
  }

  .speaker-card-arrow {
    right: -5px;
    top: 98px;
    width: 60px;
    height: 60px;
  }
  .speaker-card-arrow > svg {
    width: 60px;
    height: 60px;
  }

  .speaker-card-img > img {
    width: 120px;
    object-fit: cover;
  }

  .speaker-card-container {
    grid-template-columns: auto 10px;
    grid-template-rows: 10px auto;
  }

  .speaker-list-container {
    grid-row-gap: 40px;
  }

  .speaker-card-wrapper {
    display: grid;
    grid-template-columns: auto 110px;
    /* width: 480px; */
    grid-gap: 8px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(10px);
    width: 100%;
  }

  .speaker-page-tag-text {
    font-size: 12px;
  }

  .qmark {
    width: 40px;
  }

  .react-add-to-calendar__dropdown > ul {
    padding-left: 12px;
  }

  .react-add-to-calendar__dropdown > ul > li {
    padding: 0 12px;
  }

  .react-add-to-calendar__dropdown > ul > li:last-child {
    padding: 0 0 0 12px;
  }
}

@media (max-width: 320px) {
  .speaker-card-img {
    right: -10px;
    top: -10px;
    width: 120px;
    height: 120px;
  }

  .speaker-card-img > img {
    width: 120px;
    object-fit: cover;
  }

  .speaker-card-small-text-1 {
    font-size: 14px;
  }

  .speaker-card-small-text-2 {
    font-size: 10px;
  }
  .speaker-card-small-text-3 {
    font-size: 14px;
  }

  .speaker-card-tag-text {
    font-size: 10px;
  }

  .speaker-card-content {
    grid-gap: 24px;
  }

  .speaker-card-tags-wrapper {
    grid-gap: 8px;
  }
}
